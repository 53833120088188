import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer"
import NavBar from "./NavBar"
import '../styles/scss/main.scss';

function Layout({ children, page }) {
  return (
          <div>
            {page !== 'index' ? <NavBar /> : null}
            
            {children}
            <Footer />
          </div>
)}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
