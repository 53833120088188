import React, {useState} from 'react'
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from 'react-loader-spinner'
import axios from 'axios'

const phoneRegExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/

function QuickContactForm({setModalIsOpen}) {

  const [sended, setsended] = useState(null)
  const [loading, setloading] = useState(false)


    return (
        <Formik
              initialValues={{ email: "", firstname: "", lastname: "", phone: "" }}
              onSubmit={async (values, {resetForm}) => {
                try {
                  setloading(true)
                  setsended(null)
                 
                  const response = await axios.post('/api/handelForm', values)
                  console.log('ici')
                  if(response.status === 201) {
                    setsended(true)
                    setModalIsOpen(true)
                  } else {
                    setsended(false)
                  }
                  setloading(false)
                  resetForm()
                } catch (err) {
                  console.log(err)
                  setsended(false)
                  setloading(false)
                }
              }}
              validationSchema={Yup.object().shape({
                firstname: Yup.string().min(3, "Minimum 3 caractères").required("Veuillez indiquer votre prénom"),
                lastname: Yup.string().min(3, "Minimum 3 caractères").required("Veuillez indiquer votre nom"),
                email: Yup.string().email("L'adresse email n'est pas valide").required("Veuillez indiquer une adresse email"),
                phone: Yup.string().matches(phoneRegExp, "Numéro de téléphone invalide"),
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset
            } = props;
            return (
              <form onSubmit={handleSubmit} className="relative">

              <div className={`container mx-auto flex flex-col pb-12`}>
                  <div className="flex flex-col justify-center my-10">
                    <div className="flex  flex-wrap justify-center mb-4">
                      <div className="mx-4 mb-4 md:mb-0">
                        <input
                          id="firstname"
                          placeholder="Prénom *"
                          type="text"
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`input text-gray-800`}  
                      
                        />
                        {errors.firstname && touched.firstname && (
                          <div className="text-red-500 text-sm mt-2">{errors.firstname}</div>
                        )}
                      </div>
                      
                      <div className="mx-4">
                        <input
                          id="lastname"
                          placeholder="Nom *"
                          type="text"
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`input text-gray-800`}
                        />
                        {errors.lastname && touched.lastname && (
                          <div className="text-red-500 text-sm mt-2">{errors.lastname}</div>
                        )}
                      </div>
                    </div>
                    
                    <div className="flex flex-wrap justify-center">
                      <div className="mx-4 mb-4 md:mb-0">
                        
                        <input
                          id="email"
                          placeholder="Adresse email *"
                          type="text"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`input text-gray-800`}
                        />
                        {errors.email && touched.email && (
                          <div className="text-red-500 text-sm mt-2">{errors.email}</div>
                        )}
                      </div>
                      
                      <div className="mx-4">
                        <input
                          id="phone"
                          placeholder="Téléphone"
                          type="text"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`input text-gray-800`}
                        />
                        {errors.phone && touched.phone && (
                          <div className="text-red-500 text-sm mt-2">{errors.phone}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  </div>
                <div className="text-center">
                  {sended === false ? 
                  
                    <div className="bg-red-500 w-max mx-auto rounded-lg p-5 mb-5 text-gray-50">
                      <p>
                        Oups ! Une erreur a empêché d'envoyer votre message. <br />
                        Vous pouvez m'envoyez un mail à contact@j-rd.fr en attendant que le problème soit résolu.
                      </p>
                    </div>
                  
                  : null}
                  {sended === true ? 
                    <div className="bg-green-500 w-max mx-auto rounded-lg p-5 mb-5 text-gray-50">
                      <p>
                        Votre message à bien été envoyé.
                      </p>
                    </div>
                  
                  : null}
                  
                  {
                    !sended ? <button type="submit" disabled={sended || loading} className="mx-auto hover:underline rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 gradient text-white uppercase anton tracking-widest text-xl transform hover:scale-125 transition duration-150 focus:outline-none focus:ring focus:border-blue-300 flex flex-col items-center"><span>Envoyer</span>{loading ? <Loader type="Circles" color="#00BFFF" height={25} width={25}/> : null }</button>
                    : <button onClick={() => setModalIsOpen(false)} className="mx-auto hover:underline rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 gradient text-white uppercase anton tracking-widest text-xl transform hover:scale-125 transition duration-150 focus:outline-none focus:ring focus:border-blue-300 flex flex-col items-center"><span>Fermer</span></button>
                  }  
                </div>
                
              </form>
            );
          }}
        </Formik>
    )
}

export default QuickContactForm
