import React from 'react'
import {Link} from 'gatsby'
import logo from '../images/LOGO.png'

function NavBar({transparent}) {
    return (
        <nav id="header" className={`w-full z-30  bg-opacity-10 shadow-lg`} style={{background: '#773fff45'}}>

              <div className=" mx-10 flex flex-wrap items-center justify-between mt-0 py-2">

                <div className="pl-4 flex items-center ">
                  
                   <Link to="/" className={`toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-white  archivo-black`}>
                    J-RD
                  </Link> 
                  
                </div>

                <div className="flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20" id="nav-content">
                  <ul className="list-reset lg:flex justify-end flex-1 items-center">
                    <li className="mr-3 list-none">
                      <Link className="inline-block py-2 px-4 text-gray-50 font-bold no-underline archivo-black uppercase" to="/contact-agence-web-j-rd">Contact</Link>
                    </li>
                  </ul>
                
                </div>
              </div>
        </nav>
    )
}

export default NavBar
